import Vue from 'vue';

const confirmDirective = {
  bind (el, binding, vnode) {
    const confirmedCallback = binding.value;
    let event = vnode.data.attrs['confirm-event'];
    const title = vnode.data.attrs['confirm-title'];
    const text = vnode.data.attrs['confirm-text'];
    const color = vnode.data.attrs['confirm-color'];
    const action = vnode.data.attrs['confirm-action'];
    const securityCheck = vnode.data.attrs['confirm-security-check'];

    if (event === undefined) {
      event = 'click';
    }

    const options = {};
    if (title !== undefined) {
      options.title = title;
    }
    if (text !== undefined) {
      options.text = text;
    }
    if (color !== undefined) {
      options.color = color;
    }
    if (action !== undefined) {
      options.action = action;
    }
    if (securityCheck !== undefined) {
      options.securityCheck = securityCheck;
    }

    el.handleConfirm = (e) => {
      vnode.context.$confirm(options).then(payload => {
        if (payload.confirmed) {
          confirmedCallback(payload.password);
        }
      });
    };

    el.confirmEvent = event;
    el.addEventListener(event, el.handleConfirm);
  },
  unbind (el) {
    el.removeEventListener(el.confirmEvent, el.handleConfirm);
  },
};

Vue.directive('confirm', confirmDirective);

export default confirmDirective;
