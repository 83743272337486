import Vue from 'vue';

const RESIZABLE_MARGIN = 5;

const resizableDirective = {
  bind (el, binding, vnode) {
    const resizableCallback = binding.value;
    const directions = {
      top: false,
      bottom: false,
      left: false,
      right: false,
    };
    if (vnode.data.attrs['resizable-top'] !== undefined) {
      directions.top = vnode.data.attrs['resizable-top'] !== false;
    }
    if (vnode.data.attrs['resizable-bottom'] !== undefined) {
      directions.bottom = vnode.data.attrs['resizable-bottom'] !== false;
    }
    if (vnode.data.attrs['resizable-left'] !== undefined) {
      directions.left = vnode.data.attrs['resizable-left'] !== false;
    }
    if (vnode.data.attrs['resizable-right'] !== undefined) {
      directions.right = vnode.data.attrs['resizable-right'] !== false;
    }

    let resizing = false;
    let resizingDirection = null;
    let mouseOverResizableZone = false;
    let mouseOverDirection = false;

    el.handleResizableMouseMove = (e) => {
      const bounding = el.getBoundingClientRect();

      let height = bounding.height;
      let width = bounding.width;

      // Check if the mouse is over a resizable border
      if (directions.top &&
        e.clientY >= bounding.top - RESIZABLE_MARGIN &&
        e.clientY <= bounding.top + RESIZABLE_MARGIN
      ) {
        mouseOverResizableZone = true;
        mouseOverDirection = 'top';
      } else if (directions.bottom &&
        e.clientY >= bounding.bottom - RESIZABLE_MARGIN &&
        e.clientY <= bounding.bottom + RESIZABLE_MARGIN
      ) {
        mouseOverResizableZone = true;
        mouseOverDirection = 'bottom';
      } else if (directions.left &&
        e.clientX >= bounding.left - RESIZABLE_MARGIN &&
        e.clientX <= bounding.left + RESIZABLE_MARGIN
      ) {
        mouseOverResizableZone = true;
        mouseOverDirection = 'left';
      } else if (directions.right &&
        e.clientX >= bounding.right - RESIZABLE_MARGIN &&
        e.clientX <= bounding.right + RESIZABLE_MARGIN
      ) {
        mouseOverResizableZone = true;
        mouseOverDirection = 'right';
      } else {
        mouseOverResizableZone = false;
        mouseOverDirection = null;
      }

      // Compute the new size depending on the resizable direction clicked
      if (resizingDirection === 'top') {
        height += bounding.top - e.clientY;
      } else if (resizingDirection === 'bottom') {
        height += e.clientY - bounding.bottom;
      } else if (resizingDirection === 'left') {
        width += bounding.left - e.clientX;
      } else if (resizingDirection === 'right') {
        width += e.clientX - bounding.right;
      }

      // Set appropriate cursor
      let directionToUseForCursor = null;
      if (resizingDirection !== null) {
        directionToUseForCursor = resizingDirection;
      } else if (mouseOverResizableZone) {
        directionToUseForCursor = mouseOverDirection;
      }

      switch (directionToUseForCursor) {
        case 'top':
        case 'bottom':
          el.style.cursor = 'ns-resize';
          break;
        case 'left':
        case 'right':
          el.style.cursor = 'ew-resize';
          break;
        default:
          el.style.cursor = 'default';
          break;
      }

      // Define the new component size
      if (resizing) {
        el.style.height = height + 'px';
        el.style.width = width + 'px';
        resizableCallback({
          resizing: true,
          height: height,
          width: width,
        });
      }
    };

    el.handleResizableMouseDown = (e) => {
      if (mouseOverResizableZone) {
        resizingDirection = mouseOverDirection;
        resizing = true;
      }
    };

    el.handleResizableMouseUp = (e) => {
      if (resizing) {
        const bounding = el.getBoundingClientRect();

        resizableCallback({
          resizing: false,
          height: bounding.height,
          width: bounding.width,
        });
      }
      resizing = false;
      resizingDirection = null;
    };

    document.addEventListener('mousemove', el.handleResizableMouseMove);
    document.addEventListener('mousedown', el.handleResizableMouseDown);
    document.addEventListener('mouseup', el.handleResizableMouseUp);
  },
  unbind (el) {
    document.removeEventListener('mousemove', el.handleResizableMouseMove);
    document.removeEventListener('mousedown', el.handleResizableMouseDown);
    document.removeEventListener('mouseup', el.handleResizableMouseUp);
  },
};

Vue.directive('resizable', resizableDirective);

export default resizableDirective;
